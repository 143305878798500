import type { FC, ReactElement } from 'react'
import type { PopoverContentProps, PopoverProps } from '@chakra-ui/react'
import {
  Portal,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger
} from '@chakra-ui/react'

interface IGrandPopover extends PopoverProps {
  title: string
  target: ReactElement
  contentProps?: PopoverContentProps
  children: any
  isContentInPortal?: boolean
}

const GrandPopover: FC<IGrandPopover> = ({
  title,
  target,
  children,
  contentProps,
  isContentInPortal,
  ...props
}) => {
  const popoverContent = (
    <PopoverContent color="text.default" {...contentProps}>
      {title ? <PopoverHeader fontWeight="semibold">{title}</PopoverHeader> : null}
      <PopoverArrow />
      {title ? <PopoverCloseButton _focus={{ outline: 'none' }} aria-label="close" /> : null}
      <PopoverBody>{children}</PopoverBody>
    </PopoverContent>
  )

  return (
    <Popover
      closeOnBlur
      isLazy
      placement="bottom-end"
      returnFocusOnClose={false}
      variant="responsive"
      {...props}>
      <PopoverTrigger>{target}</PopoverTrigger>

      {isContentInPortal ? <Portal>{popoverContent}</Portal> : popoverContent}
    </Popover>
  )
}

export default GrandPopover
