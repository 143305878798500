import { Button, Icon, Menu, MenuButton, MenuList } from '@chakra-ui/react'
import { type FC, type ComponentType, Fragment, useState } from 'react'
import { BiChevronDown } from 'react-icons/bi'
import { type Language } from 'ecosystem'

interface Lang {
  locale: Language
  label: string
  flagIcon: ComponentType
}

interface LangSelectorProps {
  languages: Lang[]
  defaultLocale: Language
  linkRenderer: (
    locale: Language,
    label: string,
    flagIcon: ComponentType,
    setLanguage: (language: string) => void
  ) => JSX.Element
}

const LangSelector: FC<LangSelectorProps> = ({ languages, defaultLocale, linkRenderer }) => {
  const defaultLang = languages.find((lang) => lang.locale === defaultLocale)
  const [selectedLanguage, setSelectedLanguage] = useState(
    defaultLang ? defaultLang.label : 'Language'
  )
  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton
            size="sm"
            rounded="sm"
            as={Button}
            variant="outline"
            color={isOpen ? 'gray.50' : 'gray.700'}
            rightIcon={<Icon as={BiChevronDown} boxSize={6} />}>
            {selectedLanguage}
          </MenuButton>
          <MenuList>
            {languages.map(({ locale, label, flagIcon }) => (
              <Fragment key={locale}>
                {linkRenderer(locale, label, flagIcon, setSelectedLanguage)}
              </Fragment>
            ))}
          </MenuList>
        </>
      )}
    </Menu>
  )
}

export default LangSelector
