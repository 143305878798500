'use client'
import type { FunctionComponent } from 'react'
import { useEffect, useRef, useState } from 'react'
import type { StackProps } from '@chakra-ui/react'
import { Center, Flex } from '@chakra-ui/react'
import { TbMovieOff } from 'react-icons/tb'
import useOnScreen from '../hooks/useOnScreen'
import ActivityLoader from './ActivityLoader'

interface VideoBoxProps extends StackProps {
  source: string
  playback: boolean
  videoSupportDisabled?: boolean
}

const VideoBox: FunctionComponent<VideoBoxProps> = ({
  videoSupportDisabled,
  source,
  playback,
  ...props
}) => {
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const [isError, setIsError] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const isIntersecting = useOnScreen(videoRef)

  useEffect(() => {
    //play the video only if it's in frame and is the currently selected item in the carousel
    const video = videoRef.current

    if (video !== null) {
      const isPlaying =
        video.currentTime > 0 &&
        !video.paused &&
        !video.ended &&
        video.readyState > video.HAVE_CURRENT_DATA

      if (playback && isIntersecting && !isPlaying) {
        void video.play()
      } else if (isPlaying) {
        video.pause()
        video.currentTime = 0
      }
    }
  }, [playback, isIntersecting])

  return (
    <Flex h="100%" maxH="40vh" maxW={[320, 360, 600]} mx="auto" position="relative" {...props}>
      {/* if the video support is disabled, or if the src failed to load, display a fallback */}
      {videoSupportDisabled || isError ? (
        <Center color="gray.700" h="100%" left={0} position="absolute" top={0} w="100%">
          <TbMovieOff size="40px" />
        </Center>
      ) : null}

      {isLoading ? (
        <ActivityLoader
          fontSize="xs"
          h="full"
          position="relative"
          spinnerProps={{ size: 'sm' }}
          text="loading..."
          w="full"
          zIndex={0}
        />
      ) : null}

      {!videoSupportDisabled && (
        <video
          disablePictureInPicture
          height="100%"
          muted
          onCanPlay={() => {
            setIsLoading(false)
          }}
          onError={() => {
            setIsError(true)
          }}
          onPlay={() => {
            setIsError(false)
            setIsLoading(false)
          }}
          ref={videoRef}
          width="100%">
          {/* we only support *.mp4 and *.mov files */}
          <source src={source} type="video/quicktime" />
          <source src={source} type="video/mp4" />
          Your browser doesn&apos;t support HTML5 video
        </video>
      )}
    </Flex>
  )
}

export default VideoBox
