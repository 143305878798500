import type { AssistantMessageFE, AssistantSizeStateFE } from 'ecosystem'

const mockStorage = {
  getItem(_key: string): null | string {
    return null
  },
  setItem(_key: string, _value: string): void {
    return void 0
  },
  removeItem(_key: string): void {
    return void 0
  }
}
const sessionStorage = typeof window !== 'undefined' ? window.sessionStorage : mockStorage
const localStorage = typeof window !== 'undefined' ? window.localStorage : mockStorage

class AssistantStorage {
  private static instance: AssistantStorage
  private storagePrefix = 'assistant'

  private constructor() {
    void 0
  }

  public static getInstance(): AssistantStorage {
    if (!AssistantStorage.instance) {
      AssistantStorage.instance = new AssistantStorage()
    }

    return AssistantStorage.instance
  }

  public getHistory(): AssistantMessageFE[] | null {
    try {
      const items = JSON.parse(sessionStorage.getItem(`${this.storagePrefix}-history`) || '')

      return Array.isArray(items) ? items : null
    } catch (err) {
      return null
    }
  }

  public setHistory(newHistory: AssistantMessageFE[] | null) {
    try {
      sessionStorage.setItem(`${this.storagePrefix}-history`, JSON.stringify(newHistory))
    } catch (err) {
      void 0
    }
  }

  public removeHistory() {
    sessionStorage.removeItem(`${this.storagePrefix}-history`)
  }

  public getSessionId(): string | null {
    return sessionStorage.getItem(`${this.storagePrefix}-sessionId`)
  }

  public setSessionId(sessionId: string) {
    sessionStorage.setItem(`${this.storagePrefix}-sessionId`, sessionId)
  }

  public removeSessionId() {
    sessionStorage.removeItem(`${this.storagePrefix}-sessionId`)
  }

  public getSessionUserId(): string | null {
    return sessionStorage.getItem(`${this.storagePrefix}-sessionUserId`)
  }

  public setSessionUserId(sessionUserId: string) {
    sessionStorage.setItem(`${this.storagePrefix}-sessionUserId`, sessionUserId)
  }

  public removeSessionUserId() {
    sessionStorage.removeItem(`${this.storagePrefix}-sessionUserId`)
  }

  public getSizeState(): string | null {
    return localStorage.getItem(`${this.storagePrefix}-sizeState`)
  }

  public setSizeState(sessionId: AssistantSizeStateFE) {
    localStorage.setItem(`${this.storagePrefix}-sizeState`, sessionId)
  }
}

export const assistantStorage = AssistantStorage.getInstance()
