import type { HeadingProps } from '@chakra-ui/react'
import type {
  BrandDTO,
  CategoryDTO,
  DbInfoFields,
  DeliveryDTO,
  OldStoreProductFilterDTO,
  TagDTO
} from './grand-connect'
import type { Option } from './general'

export type StorefrontWidgetTypeAll =
  | StorefrontWidgetTypeHandler
  | 'WIDGET_BLOG'
  | 'WIDGET_BLOG_CATEGORY'
  | 'WIDGET_STATIC_VIEW'
  | 'WIDGET_STATIC_VIEW_CATEGORY'
  | 'FOOTER_SECTION'
  | 'HELLO_RETAIL'
  | 'CONTACT_FORM'

export type StorefrontWidgetTypeHandler =
  | 'CAMPAIGN_HERO'
  | 'CAMPAIGN_WIDGET'
  | 'WIDGET_CTA'
  | 'IMAGE_GALLERY'
  | 'IMAGE'
  | WidgetGroupType
  | WidgetSimpleIdTypeHandler
  | WidgetDynamicType

export type WidgetSimpleIdTypeHandler =
  | 'CATEGORY_TOOLBAR'
  | 'CATEGORY_TREE'
  | 'PRODUCT_LIST'
  | 'PRODUCT_LIST_1'
  | 'WIDGET_VIDEO'
  | 'WIDGET_EMBEDDED_VIDEO'
  | 'GOOGLE_MAP_WIDGET'
  | 'DIVIDER_WIDGET'
  | 'JOINED_WIDGET'
  | 'CUSTOM_WIDGET_ID'
export const WidgetDynamicTypes = ['HERO', 'CONTENT_BOX'] as const
export type WidgetDynamicType = (typeof WidgetDynamicTypes)[number]

export const CategoryWidgetDynamicTypes = ['HERO', 'CONTENT_BOX'] as const

export type CategoryWidgetDynamicType = (typeof CategoryWidgetDynamicTypes)[number]

export type WidgetGroupType =
  | 'SQUARE_LINK'
  | 'BUBBLE_LINK'
  | 'CIRCLE_LINK'
  | 'CIRCLE_LINK_1'
  | 'SUMMARY_BOXES_LINK'
  | 'SUMMARY_BOXES_BUTTON'
  | 'SUMMARY_BOXES_BUTTON_1'
  | 'SUMMARY_BOXES_BUTTON_2'
  | 'ARTICLE_SIZED_BOXES'
  | 'ARTICLE_BOXES'
  | 'ARTICLE_BOXES_1'
  | 'ARTICLE_BOXES_2'
  | 'ACCORDION_ITEM'
  | 'COLORED_ITEMS'
  | 'TWO_COLUMNS'

export type StylesObject = Record<string, unknown>

export interface WidgetStyling {
  styling?: StylesObject
}

interface WithMobileDisplaySupport {
  showsOnMobile?: boolean
}

export interface WidgetIdentifiers {
  brandId?: string | null
  categoryId?: string | null
  tagId?: string | null
  view?: string | null
}

export type StorefrontWidget<T> = Partial<DbInfoFields> &
  WidgetIdentifiers & {
    id: string
    storeId: string
    type: StorefrontWidgetTypeAll
    widget: T
  }

export const WidgetEndpointTags = ['CATEGORY', 'TAG', 'BRAND', 'VIEW'] as const
export type WidgetEndpointTagsType = (typeof WidgetEndpointTags)[number]

export interface StorefrontWidgetsByTypeResponse<T> {
  widgets: StorefrontWidget<T>[]
}

export interface BasicWidgetType {
  id: string
  name: string
  order: number
}

export type BasicWidgetItemNew = WidgetStyling & {
  id: string
  order?: number
}

export type BasicWidgetItem = WidgetStyling & {
  id: string
  name: string
  path?: string
  imgUrl: string
  title?: string
  sub?: string
  tag?: string
  isReversed?: boolean
}

export type CustomWidgetType = BasicWidgetType & {
  widgetId: string
}

export type BubbleLinkWidgetItemType = BasicWidgetItemNew & {
  title?: string
  path?: string
  isExternal?: boolean
}
export type BubbleLinkWidgetType = WidgetGroup<BubbleLinkWidgetItemType> &
  WidgetStyling & {
    title?: string
    description?: string
  }

export type CircleLinkWidgetItemType = BasicWidgetItemNew & {
  title?: string
  path?: string
  imgUrl?: string
  sub?: string
  tag?: string
  isExternal?: boolean
}
export type CircleLinkWidgetType = BasicWidgetLink<CircleLinkWidgetItemType>

export type SquareWidgetItemType = BasicWidgetItemNew & {
  title?: string
  path?: string
  imgUrl?: string
  sub?: string
  tag?: string
  isExternal?: boolean
}
export type SquareWidgetType = BasicWidgetLink<SquareWidgetItemType>

export type CampaignWidgetItemType = BasicWidgetGroupItem & {
  title: string
  path: string
  imgUrl: string
  isExternal?: boolean
}
export type CampaignWidgetType = {
  items: CampaignWidgetItemType[]
} & BasicWidgetType &
  WidgetStyling &
  WithMobileDisplaySupport

export type CampaignHeroWidgetType = BasicWidgetType &
  WidgetStyling &
  WithMobileDisplaySupport & {
    alt: string
    imgUrl: string
    path: string
    sub: string
    title: string
    name: string
    mobileImgUrl: string | null
    isExternal?: boolean
    withAspectRatio: boolean
  }

export type ContentBoxWidgetType = BasicWidgetType &
  WidgetStyling &
  WithMobileDisplaySupport & {
    content: string | null
    contentMode: 'AUTO' | 'CUSTOM'
  }

export type HeroWidgetType = BasicWidgetType &
  WidgetStyling &
  WithMobileDisplaySupport & {
    path: string
    title: string
    sub: string
    alt: string
    imgUrl: string
    mobileImgUrl: string | null
    ribbonText?: string
    priority?: boolean
    cta?: {
      label: string
      path: string
      isExternal: boolean
    }
    headingTag: HeadingProps['as']
    slogan: string
    mobileLayout: 'DEFAULT' | 'COMPACT'
    titleMode: 'CUSTOM' | 'AUTO'
    subMode: 'CUSTOM' | 'AUTO'
    imageMode: 'CUSTOM' | 'AUTO'
    withAspectRatio?: boolean
  }

export type Ratio = '16:9' | '4:3' | '3:2' | '8:5' | '33:10' | '1:1'

export type BasicWidgetLink<T> = {
  items: T[]
  title?: string
  description?: string
  itemsAspectRatio?: Ratio
} & BasicWidgetType &
  WidgetStyling &
  WithMobileDisplaySupport

export type WidgetLinkVariant = 'sm' | 'md' | 'lg' | 'xl'

export type WidgetLinkFull<T> = BasicWidgetLink<T> & {
  variant?: WidgetLinkVariant
}

export type WidgetSectionButton<T> = BasicWidgetLink<T> & {
  btnLabel?: string
  path?: string
  isExternal?: boolean
  itemDesktopMinWidth?: string
}

export type SummaryBoxesWidgetItemType = BasicWidgetItemNew & {
  title?: string
  path?: string
  imgUrl?: string
  sub?: string
  tag?: string
  isExternal?: boolean
  isReversed?: boolean
}
export type SummaryBoxesWidgetType = BasicWidgetLink<SummaryBoxesWidgetItemType>

export type SummaryBoxesButtonWidgetItemType = BasicWidgetItemNew & {
  title?: string
  path?: string
  imgUrl?: string
  sub?: string
  tag?: string
  isExternal?: boolean
  btnLabel?: string
}
export type SummaryBoxesButtonWidgetType = BasicWidgetLink<SummaryBoxesButtonWidgetItemType>

export type WidgetCTAWidgetType = BasicWidgetType &
  WidgetStyling &
  WithMobileDisplaySupport & {
    text?: string
    sub?: string
    btnLabel: string
    path: string
    headingTag: string
    isExternal: boolean
  }

export type SimpleIdWidgetType = BasicWidgetType & {
  widgetId: string
  [extraProp: string]: string | number | boolean
} & WithMobileDisplaySupport

export type WidgetSingleViewId = 'STATIC_VIEWS' | 'LANDING' | 'FOOTER' | 'BLOG' | 'SHARED_WIDGETS'

export const WidgetDynamicViews = ['CATEGORY', 'TAG', 'BRAND'] as const

export type WidgetDynamicViewId = (typeof WidgetDynamicViews)[number]

// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents -- migration from SFA
export type WidgetViewId = WidgetSingleViewId | WidgetDynamicViewId | string

export interface ImageGalleryWidgetItemType {
  id: string
  order: number
  imgUrl: string
  alt: string
  rowSpan: number
  colSpan: number
  path?: string
  isExternal?: boolean
}

export type ImageGalleryWidgetType = BasicWidgetType &
  WithMobileDisplaySupport &
  WidgetStyling & {
    items: ImageGalleryWidgetItemType[]
    templateColumns: number
    templateColumnsMobile: number
  }

export type ImageWidgetType = BasicWidgetType &
  WidgetStyling &
  WithMobileDisplaySupport & {
    imgUrl: string
    title?: string
    sub?: string
    mobileImgUrl?: string
    aspectRatio: Ratio
    mobileAspectRatio?: Ratio
  }

export type AccordionWidgetItemType = BasicWidgetGroupItem & {
  htmlSnippet: string
  title?: string
}
export type AccordionWidgetType = WidgetGroup<AccordionWidgetItemType>

export type WidgetGroup<T> = BasicWidgetType &
  WithMobileDisplaySupport &
  WidgetStyling & {
    items?: T[]
  }

export interface BasicWidgetGroupItem {
  id: string
  order?: number
}

export type VideoWidgetType = BasicWidgetType &
  WithMobileDisplaySupport & {
    videoUrl: string
  }

export type EmbeddedVideoWidgetType = VideoWidgetType

export type MapWidgetType = BasicWidgetType &
  WithMobileDisplaySupport &
  WidgetStyling & {
    title?: string
    description?: string
    lat: string
    lng: string
    sub?: string
    layout?: 'DEFAULT' | 'COMPACT'
  }

export interface FooterItem {
  name: string
  sectionTitle: string
  items: FooterSectionWidgetItemType[]
}

export type FooterSectionWidgetItemType = BasicWidgetGroupItem & {
  label?: string
  text?: string
  path?: string
  isExternal?: boolean
}

export type FooterSectionWidgetType = BasicWidgetType &
  WidgetStyling & {
    sectionTitle: string
    items: FooterSectionWidgetItemType[]
  }

export enum DynamicWidgetType {
  CATEGORY = 'CATEGORY',
  BRAND = 'BRAND',
  TAG = 'TAG'
}

export interface DynamicWidgetProps {
  viewId: string
  type: DynamicWidgetType
  filterConfig: OldStoreProductFilterDTO
  deliveryOptions: Option<DeliveryDTO[]>
  obj: CategoryDTO | TagDTO | BrandDTO
}

export type ArticleBoxesWidgetType = BasicWidgetLink<ArticleBoxesWidgetItemType> & {
  path?: string
  btnLabel?: string
  isExternal?: boolean
  itemDesktopMinWidth?: string
}
export type ArticleBoxesWidgetItemType = BasicWidgetItemNew & {
  path?: string
  imgUrl?: string
  title?: string
  sub?: string
  label?: string
  isExternal?: boolean
}

export type ArticleSizedBoxesWidgetType = WidgetLinkFull<ArticleSizedBoxesWidgetItemType> & {
  path?: string
  btnLabel?: string
  isExternal?: boolean
}

export type ArticleSizedBoxesWidgetItemType = BasicWidgetItemNew & {
  imgUrl?: string
  path?: string
  sub?: string
  title?: string
  subtitle?: string
  showSubOnMobile?: boolean
  isExternal?: boolean
  label?: string
}

export type ColoredItemsWidgetType = WidgetLinkFull<ColoredItemsWidgetItemType>

export type ColoredItemsWidgetItemType = BasicWidgetItemNew & {
  title: string
  path?: string
  imgUrl?: string
  isExternal?: boolean
  color: string
}

export type DividerWidgetType = BasicWidgetType & WidgetStyling & WithMobileDisplaySupport

export type TwoColumnsWidgetType = BasicWidgetType &
  WidgetStyling &
  WithMobileDisplaySupport & {
    items?: TwoColumnsWidgetItemType[]
    title?: string
    leftColumnContent?: string
    rightColumnContent?: string
    path?: string
    isExternal?: boolean
    btnLabel?: string
  }

export type TwoColumnsWidgetItemType = BasicWidgetGroupItem & {
  title?: string
  content?: string
}

export type JoinedWidgetType<RelatedSharedWidgetType = StorefrontWidget<unknown>> =
  BasicWidgetType &
    WidgetStyling &
    WithMobileDisplaySupport & {
      title?: string
      relatedSharedWidgets: RelatedSharedWidgetType[]
      columnsNumber?: string
      columnsNumberMobile?: string
      isWrapped?: boolean
    }

export interface RelatedWidget {
  id: string
  name: string
  imgUrl: string
  slug?: string
}

interface WidgetBasicCategory {
  id: string
  name: string
  title: string
  description: string
  order?: number
}

export type WidgetBlog = BasicWidgetType & {
  tag: string
  imgUrl: string
  title: string
  alt: string
  summary: string
  date: string
  author: string
  relatedProducts: RelatedWidget[]
  slug: string
  content: string
}

export type WidgetBlogCategory = WidgetBasicCategory & {
  relatedBlogPosts: RelatedWidget[]
}

export interface WidgetStaticPage {
  id: string
  name: string
  title: string
  description: string
  viewId: string
  slug: string
  imgUrl: string
}

export type WidgetStaticPageCategory = WidgetBasicCategory & {
  relatedStaticPages: RelatedWidget[]
}
