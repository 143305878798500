import type { CategoryDTO } from 'ecosystem'

export const filterCategories = (categories?: CategoryDTO[] | null): CategoryDTO[] => {
  if (!categories) {
    return []
  }

  return sortCategories(categories).map((category) => {
    return {
      ...category,
      ...(category.subCategories?.length
        ? {
            subCategories: filterCategories(category.subCategories)
          }
        : {})
    }
  })
}
export const sortCategories = (categories: CategoryDTO[]) =>
  categories.filter((c) => c.order).sort((a, b) => a.order - b.order)
