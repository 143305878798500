import { type Language } from 'ecosystem'

const dictLocales: Record<Language, string> = {
  en: 'en-GB',
  sv: 'sv-SE',
  da: 'da-DK',
  no: 'nb-NO'
}
export const getFullLocale = (locale: Language) => {
  const foundLocale = dictLocales[locale]

  return foundLocale ?? locale
}
